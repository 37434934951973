import * as actionTypes from './action_type'
import { CommonService } from '../../../../../src/_services/CommonService'
import { message } from "antd";

export const setAllCars = (data) => {
    return {
        type: actionTypes.SET_CARS,
        data
    }
}

export const setAllCarType = (data) => {
    return {
        type: actionTypes.SET_CARS_TYPE,
        data
    }
}

export const setAllCarRate = (data) => {
    return {
        type: actionTypes.SET_CARS_RATE,
        data
    }
}

export const setUnallocatedDrivers =  (data) => {
    return {
        type: actionTypes.SET_UAC_DRIVERS,
        data
    }
}

export const getAllCars = () => {
    return (dispatch) => {
        return CommonService.httpGetService('/cars/car').then((res) => {
            if (res && res['data']) {
                dispatch(setAllCars(res['data']))
            } else {
                dispatch(setAllCars(null))
            }
        }).catch(err => {
            message.error(err);
        })
    }
}

export const createCar = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/cars/car', data).then((res) => {
            dispatch(getAllCars(res))
            dispatch(getUnallocatedDrivers())
            message.success(res.message)
        }).catch((err) => {
            message.error(err);
        })
    }
}

export const editCar = (data) => {
    return (dispatch) => {
        return CommonService.httpPutService('/cars/car', data).then((res) => {
            dispatch(getAllCars(res))
            dispatch(getUnallocatedDrivers())
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const deleteCar = (id) => {
    return (dispatch) => {
        return CommonService.httpDeleteService(`/cars/car/${id}`).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }

}

export const getAllCarType = () => {
    return (dispatch) => {
        return CommonService.httpGetService('/cars/type').then((res) => {
            if (res && res['data']) {
                dispatch(setAllCarType(res['data']))
            } else {
                dispatch(setAllCarType(null))
            }
        }).catch(err => {
            message.error(err);
        })
    }
}

export const createCarType = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/cars/type', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const updateCarType = (data) => {
    return (dispatch) => {
        return CommonService.httpPutService('/cars/type', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const deleteCarType = (id) => {
    return (dispatch) => {
        return CommonService.httpDeleteService(`/cars/type/${id}`).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err)
        })
    }
}

export const getAllCarRate = () => {
    return (dispatch) => {
        return CommonService.httpGetService('/cars/rate/intercity').then((res) => {
            if (res && res['data']) {
                dispatch(setAllCarRate(res['data']))
            } else {
                dispatch(setAllCarRate(null))
            }
        }).catch(err => {
            message.error(err);
        })
    }
}

export const createCarRate = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/cars/rate/intercity', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const updateCarRate = (data) => {
    return (dispatch) => {
        return CommonService.httpPutService('/cars/rate/intercity', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const deleteCarRate = (id) => {
    return (dispatch) => {
        return CommonService.httpDeleteService(`/cars/rate/intercity/${id}`).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err)
        })
    }
}

export const getUnallocatedDrivers = () => {
    return (dispatch) => {
        return CommonService.httpGetService('/cars/driver/unallocated').then((res) => {
            if (res && res['data']) {
                dispatch(setUnallocatedDrivers(res['data']))
            } else {
                dispatch(setUnallocatedDrivers(null))
            }
        }).catch(err => {
            message.error(err);
        })
    }
}
